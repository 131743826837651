import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class RouterStateService {
  data$ = inject(Store).select(
    (state: { router: { state: { datas: Record<string, any> } } }) => state.router.state.datas,
  );
}
